import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Footer from "./footer";
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";

export default function FormDialog(props) {
    const [state, setState] = React.useState({
        phoneNumberInput: "",
    });


    const { openDialog, openCloseDialogAction, agreeToTerm,
        agreeToTermsClicked, clickedAccepted, acceptInvite,
        reminderId
    } = props

    const handleAgreeToTerms = (e) => {
        agreeToTermsClicked(!agreeToTerm)
    }

    const handleClose = () => {
        // Handle close
        openCloseDialogAction(false)
    };

    const handleVerify = () => {
        // TODO: Handle verify
        console.log(reminderId, state.phoneNumberInput, clickedAccepted)
        acceptInvite(reminderId, state.phoneNumberInput, clickedAccepted)
    }

    const handlePhoneNumberInput = (event) => {
        let value = event.target.value
        setState({ ...state, [event.target.name]: value});
    }

    const showTermsBox = () => {
        if (clickedAccepted) {
            return (
                <FormControlLabel
                    style={{paddingTop: '5px'}}
                    control={<Checkbox checked={agreeToTerm} onChange={handleAgreeToTerms} name="checkedA" />}
                    label={
                        <Typography variant="caption" component="p" color={"secondary"} >
                            I agree to accept this reminder from Text Me That via sms.
                        </Typography>
                    }
                />
            )
        } else {
            return null
        }
    }

    const disableVerifyButton = () => {
        // If the user has clicked the accept button and has not agree to terms, then disable
        return !!(clickedAccepted && !agreeToTerm);
    }

    return (
        <div>
            <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Verify Phone Number</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please verify your phone number to {clickedAccepted ? 'accept' : 'deny'} this reminder.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Phone number"
                        type="tel"
                        fullWidth
                        name={"phoneNumberInput"}
                        onChange={handlePhoneNumberInput}
                    />
                    {showTermsBox()}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        Cancel
                    </Button>
                    <Button onClick={handleVerify} color="primary" disabled={disableVerifyButton()}>
                        Verify
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}