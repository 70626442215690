import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../redux/actions/groupReminderActions";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

/** Components */
import VerificationModal from "../components/verificationModal";
import ReminderCard from "../components/card";
import PrimaryTextField from "../components/textfield";
import PrimaryButton from "../components/button";

class GroupReminderPage extends Component {
  constructor(props) {
    super(props)


    props.getReminderData(props.match.params.id)
  }

  handleGroupTextField(phoneNumber) {
    this.props.updateGroupPhoneNumberTextField(phoneNumber);
  }

  handleAddToGroupButton() {
    // If the verification code is already sent and the number has not changed, then open the modal
    if (this.props.group.verificationCodeSent) {
      this.props.updateModal(true);
    } else {
      this.props.requestVerification(this.props.group.phoneNumber);
    }
  }

  renderModal() {
    return <VerificationModal id={this.props.match.params.id} />;
  }

  renderMessage() {
    // If there is no error Message. Then dont' return a component
    if (this.props.group.message == null) {
      return null;
    }

    let textColor = this.props.group.errorExists ? "red" : "green";

    return (
      <Typography
        variant="subtitle2"
        color="textPrimary"
        component="p"
        align="justify"
        style={{ color: textColor }}
        gutterBottom
      >
        {this.props.group.message}
      </Typography>
    );
  }

  renderTextField() {
    // If the user has been added to the group, then hide the text field.
    if (this.props.group.addedToGroup === true) {
      return null;
    }

    return (
      <PrimaryTextField
        type={"tel"}
        handleTextField={this.handleGroupTextField.bind(this)}
        textValue={this.props.group.phoneNumber}
        placeholder={"Phone Number"}
        helperText={"Where would Text Me That send the reminder?"}
      />
    );
  }

  renderCard() {
    console.log('reminder.', this.props.group.reminderTime)
    return <ReminderCard reminderMessage={this.props.group.reminderMessage} reminderNotes={this.props.group.reminderNotes} time={this.props.group.reminderTime}/>;
  }

  renderButton() {
    return (
      <PrimaryButton
        disabled={this.props.group.addedToGroup}
        title={"Remind Me!"}
        buttonPushed={this.handleAddToGroupButton.bind(this)}
      />
    );
  }

  render() {
    console.log("props", this.props.match);

    return (
      <Grid
        container
        direction={"column"}
        justify="space-evenly"
        alignItems="center"
      >
        <Typography variant="h5" component="h3" color={"secondary"} >
          Text Me That has updated the version 4.
          <br/>
          To use group reminders, please update the app to the latest version on the app store.
        </Typography>
      </Grid>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(
  mapStateToProps,
  actions
)(GroupReminderPage);
