import { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../redux/actions/groupReminderActions";
// import { Redirect } from "react-router-dom";

class HomePage extends Component {
  render() {
    // window.location.href = 'https://www.textmethat.io'
    return null;
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(
  mapStateToProps,
  actions
)(HomePage);
