import React from "react";
import { connect } from "react-redux";
import * as actions from "../redux/actions/groupReminderActions";

/** Components */
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import PrimaryTextField from "../components/textfield";
import PrimaryButton from "../components/button";

function getModalStyle() {
  const top = 50
  const left = 50

  let width = window.innerWidth
  const isMobile = width <= 500;

  if (isMobile) {
    return {
      top: `${0}%`,
      left: `${0}%`,
      transform: `translate(-${0}%, -${0}%)`,
    };
  } else {
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
  }


}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
    alignItems: "right"
  }
}));

function VerificationModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    props.updateModal(false);
  };

  const submitAuthCode = () => {
    console.log("props", props)
    props.submitAuthCode(props.group.token, props.group.phoneNumber, props.id)
  }

  const updateAuthToken = (value) => {
    props.updateToken(value)
  }

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      // open={props.group.presentVerificationModal}
      open={props.group.presentVerificationModal}
      onClose={handleClose}
    >
      <div className={classes.paper} style={modalStyle}>
        <Typography variant="h6" id="modal-title">
          Auth Code
        </Typography>
        <Typography variant="subtitle1" id="simple-modal-description">
          Help prove that you're not a robot!
        </Typography>
        <Typography variant="subtitle2" id="simple-modal-description2">
          A text code has been sent to you.
        </Typography>
        <br />
        <PrimaryTextField
          handleTextField={updateAuthToken}
          textValue={props.group.token}
          placeholder={"Auth Code"}
          helperText={"Enter your auth code here."}
          type={'number'}
        />
        <br />
        <PrimaryButton buttonPushed={submitAuthCode} title="Submit Auth Code" />
        <Button onClick={handleClose}>Cancel</Button>
      </div>
    </Modal>
  );
}

function mapStateToProps(state) {
  return state;
}

export default connect(
  mapStateToProps,
  actions
)(VerificationModal);
