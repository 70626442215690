import React from "react";
import { render } from "react-dom";

const footerStyle = {
    backgroundColor: "white",
    fontSize: "20px",
    color: "white",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "160px",
    width: "100%",
};

const phantomStyle = {
    display: "block",
    padding: "20px",
    height: "60px",
    width: "100%",
    marginTop: "100px"
};

export default function Footer({ children }) {
    return (
        <div>
            <div style={phantomStyle} />
            <div style={footerStyle}>{children}</div>
        </div>
    );
}