import React from "react"

import logo from '../resources/images/logo_v6.png'

//** Card UI*/
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { isAndroid } from  'react-device-detect'


function Title() {
    return (
        <Grid
            item
        >
            <Grid
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
            >
                <Grid item>
                    <img src={logo} alt={"logo"} style={{width: "50px", height: "50px"}}/>
                </Grid>

                <Grid
                    item
                >
                    <Grid
                        item
                        container
                        direction="column"
                        justify="flex-start"
                        alignItems="flex-start"
                        style={{paddingLeft: "15px"}}
                    >
                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="subtitle1"
                            >
                                Text Me That for Android
                            </Typography>
                        </Grid>

                        <Grid item>
                            <Typography
                                color="textPrimary"
                                variant="subtitle2"
                            >
                                Sign up for the beta today
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default function AndroidBetaBanner(props) {

    if (!isAndroid) {
        return null
    }


    return (
        <Card minwidth={400} raised={true} color={'#f0f0f0'}>
            <CardContent>
                <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                >
                    {Title()}
                    <Grid item>
                        <Button href="https://textmethat.io/android-beta" color="secondary">
                            VIEW
                        </Button>
                    </Grid>
                </Grid>

            </CardContent>
        </Card>
    )
}
