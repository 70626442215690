import * as type from "../types";

const initialState = {
  // Phone number of the user trying to be added to the group.
  phoneNumber: "",

  // To be displayed added successfully or there is an error
  message: null,

  // If there is an error, make the text red
  errorExists: false,

  // If addedToGroup, then was successful, and show success message and make Error Exists false
  addedToGroup: false,

  // If verification code sent, then present modal. IF the user taps "Remind ME!" again, and this is true, then present the modal
  verificationCodeSent: false,

  // This is the modal that they type their verification token
  presentVerificationModal: false,

  // Verified --> If the use passed, then they are verified
  verified: false,

  // The verification token
  token: "",

  // Needed to be able to be added to the reminder
  reminderID: "",

  reminderMessage: "",

  reminderNotes: "",

  reminderTime: "",

  // Version TMT CLIENT 4 Properties
  notification: "",

  frequency: "",

  openDialog: false,

  agreeToReceivingReminder: false,

  // This variable tells us if the reminder has been successfully rejected or denied by the user
  finishedResponse: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case type.UPDATE_REMINDER_DATA:
      state = {
        ...state,
        ...action.payload
      };

      return state;

    case type.UPDATE_TOKEN:
      state = { ...state, token: action.payload };

      return state;

    case type.UPDATE_MODAL:
      state = { ...state, presentVerificationModal: action.payload };

      return state;

    case type.UPDATE_MESSAGE:
      state = { ...state, message: action.payload };

      return state;

    case type.UPDATE_ERROR:
      state = { ...state, errorExists: action.payload };

      return state;

    case type.VERIFICATION_SENT:
      state = { ...state, verificationCodeSent: action.payload };

      return state;

    case type.UPDATE_GROUP_PHONE_NUMBER_TEXTFIELD:
      state = { ...state, phoneNumber: action.payload };

      return state;

    case type.ADD_USER_TO_GROUP:
      state = { ...state, addedToGroup: action.payload };

      return state;

    case type.UPDATE_DIALOG_OPEN:
      state = { ...state, openDialog: action.payload };

      return state;

    case type.UPDATE_AGREE_TO_TERMS:
      state = { ...state, agreeToReceivingReminder: action.payload };

      return state;

    case type.UPDATE_FINISHED_RESPONSE:
      state = { ...state, finishedResponse: action.payload };

      return state;
    default:
      return state;
  }
};
