import { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../redux/actions/groupReminderActions";
// import { Redirect } from "react-router-dom";

class AppPage extends Component {
  render() {

    let width = window.innerWidth
    const isMobile = width <= 1000;

    const url =
      process.env.NODE_ENV === "production"
        ? window.location.protocol.concat("//").concat(window.location.host) + "/app/group/reminder?id=" +
          this.props.match.params.id
        : "http://c991215f55ac.ngrok.io/app/group/reminder?id=" + this.props.match.params.id;


    if (isMobile) {
          setTimeout(function() {
            window.location = url;
          }, 1000);

          window.location = `textmethatthree://group/reminder?id=${
            this.props.match.params.id
          }`;
    } else {
        console.log("redirecting to /group/")
        window.location = url;
    }

    return null;
  }
}

function mapStateToProps(state) {
  return state;
}

export default connect(
  mapStateToProps,
  actions
)(AppPage);
