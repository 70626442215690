import * as type from "../types";
import axios from "axios";
import moment from "moment";

const url = process.env.NODE_ENV === "production" ? "" : "";

let errorTypes = {
  invalid_country_code: "invalid-country-code",
  too_long: "too-long",
  too_short: "too-short",
  unknown: "unknown",
  incorrect_format: "incorrect-format",
  not_invited: "not_invited",
  no_group: "no_group"
};

export const updateDialog = (open) => async dispatch => {
  dispatch({ type: type.UPDATE_DIALOG_OPEN, payload: open });
}

export const agreeToTerm = (agree) => async dispatch => {
  dispatch({ type: type.UPDATE_AGREE_TO_TERMS, payload: agree });
}

export const acceptInvite = (reminderId, phoneNumber, accepted) => async dispatch => {
  axios
      .post(`${url}/api/group/invited/${reminderId}`,
          { phoneNumber: phoneNumber, accepted: accepted }
          )
      .then(response => {
        console.log(response.data.error)
        if (response.data.error) {
          alert(response.data.error.message)
        } else {
          // Success!
          // Handle that they are finished looking at this view.
          dispatch({ type: type.UPDATE_FINISHED_RESPONSE, payload: true });
          dispatch(updateDialog(false))
        }
      })
      .catch(function(error) {
        alert(error.message)
      });
}

export const getReminderData = reminderId => async dispatch => {
  axios.get(`${url}/api/group/reminder/${reminderId}`).then(response => {

    if (response.data.error !== undefined) {
      // This is to lock the button beacuse this is a broken reminder
      dispatch({ type: type.UPDATE_ERROR, payload: true });
      dispatch({
        type: type.UPDATE_MESSAGE,
        payload: response.data.error.message
      });
      return;
    }

    // Do all the dataformatting right here.
    const options = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};

    let data = response.data
    data.reminderID = reminderId

    // REMINDER NOTES
    data.reminderNotes = data.reminderNotes === "" || data.reminderNotes === undefined ? "No Description" : data.reminderNotes

    // Reminder NOTIFICATION
    data.notification = getFrequencyStopMessage(data.frequency, data.notification)

    // Reminder Frequency
    data.frequency = getFrequencyMessage(data.frequency)

    // Reminder TIME
    data.reminderTime = `Reminder starts on: ${moment(data.reminderTime).format("MMMM Do YYYY, h:mm a")}`

    dispatch({ type: type.UPDATE_REMINDER_DATA, payload: data });
  }).catch(error => {
    console.log(error)
  });
};

function getFrequencyMessage(frequency) {
  switch (frequency) {
    case 'once':
      return "This is a one time reminder."
    case "daily":
      return "This reminder will be sent everyday."
    case "biweekly":
      return "This reminder will be sent every other week."
    case "weekly":
      return "This reminder will send weekly."
    case "monthly":
      return "This reminder will send monthly."
    case "quarterly":
      return "This reminder will send every 3 months."
    case "sixmonths":
      return "This reminder will send every 6 months."
    case "yearly":
      return "This reminder will send once a year."
    case "weekdays":
      return "This reminder will only send on the weekdays (M-F)"
    case "weekends":
      return "This reminder will only send on the weekends (Sat-Sun)"
    default:
      return ""
  }
}

function getFrequencyStopMessage(frequency, notifications) {
  if (notifications <= 1) {
    return "This reminder will only be sent once then stop."
  }

  switch (frequency) {
    case "daily":
      return `This reminder will stop after ${notifications} days`
    case "biweekly":
      return `This reminder will stop after ${notifications * 2} weeks`
    case "weekly":
      return `This reminder will stop after ${notifications} weeks`
    case "monthly":
      return `This reminder will stop after ${notifications} months`
    case "quarterly":
      return `This reminder will stop after ${notifications * 3} months`
    case "sixmonths":
      return `This reminder will stop after ${notifications * 6} months`
    case "yearly":
      return `This reminder will stop after ${notifications} year`
    case "weekdays":
      return `This reminder will stop after ${Math.ceil(notifications / 5)} weeks`
    case "weekends":
      return `This reminder will stop after ${Math.ceil(notifications / 2)} weekends`
    default:
      return ""
  }
}

function toDateTime(secs) {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}

export const addUserToGroup = (phoneNumber, id) => async dispatch => {
  let body = {
    phoneNumber: phoneNumber
  };

  axios
    .post(`${url}/api/group/reminder/${id}`, body)
    .then(response => {
      if (response.data.error.message) {
        dispatch({ type: type.UPDATE_ERROR, payload: true });
        dispatch({
          type: type.UPDATE_MESSAGE,
          payload: response.data.error.message
        });
      } else {
        // Success! - Mark that user has been added to group
        dispatch({ type: type.ADD_USER_TO_GROUP, payload: true });
        dispatch({
          type: type.UPDATE_MESSAGE,
          payload: "You have been added to the reminder!"
        });
      }
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const submitAuthCode = (token, phoneNumber, id) => async dispatch => {
  // Hide Modal
  dispatch({ type: type.UPDATE_MODAL, payload: false });

  let body = {
    token: token,
    phoneNumber: phoneNumber
  };

  axios
    .post(`${url}/verification_check`, body)
    .then(response => {
      console.log("response", response);

      if (response.data.error.message) {
        console.log("error Exists", response.data);
        dispatch({ type: type.UPDATE_ERROR, payload: true });
        dispatch({
          type: type.UPDATE_MESSAGE,
          payload: response.data.error.message
        });
      } else {
        // Success! - Now try to add use to the group - And Add that the user is verified
        dispatch({ type: type.UPDATE_VERIFIED, payload: true });
        dispatch({ type: type.VERIFICATION_SENT, payload: true });

        // Update as per usual - Clear the error, and message. Message will be updated from next function
        dispatch({ type: type.UPDATE_ERROR, payload: false });
        dispatch({ type: type.UPDATE_MESSAGE, payload: null });

        // Attempt to add user to reminder group
        console.log("Try to add user to group");
        dispatch(addUserToGroup(phoneNumber, id));
      }
    })
    .catch(function(error) {
      console.log(error);
    });
};

export const updateToken = token => async dispatch => {
  dispatch({ type: type.UPDATE_TOKEN, payload: token });
};

export const updateModal = open => async dispatch => {
  dispatch({ type: type.UPDATE_MODAL, payload: open });
};

export const updateGroupPhoneNumberTextField = phoneNumber => async dispatch => {
  // If User updates the phone number field, then a new verification code needs to be sent.
  dispatch({ type: type.VERIFICATION_SENT, payload: false });

  // Update the users phone number
  dispatch({
    type: type.UPDATE_GROUP_PHONE_NUMBER_TEXTFIELD,
    payload: phoneNumber
  });
};

const getErrorMessage = error => {
  switch (error) {
    case errorTypes.invalid_country_code:
      return "Invalid Country - We currently do not support your country for reminders. 😩";
    case errorTypes.too_long:
      return "Phone number is too long";
    case errorTypes.too_short:
      return "Phone number is too short";
    case errorTypes.unknown:
      return error.message !== undefined ? error.message : "Please check your phone number"
    case errorTypes.incorrect_format:
      return "Incorrect format. We currently only support North America. (US & CA)";
    case errorTypes.not_invited:
      return "Looks like you were not invited to this reminder. Ask the owner to invite via the Text Me That App."
    case errorTypes.no_group:
      return "Weird error has occurred. We could not find the reminder. Please refresh the page."
    default:
      return "Unknown error - Please check your phone number";
  }
};

export const requestVerification = phoneNumber => async dispatch => {
  axios
    .post(`${url}/verification_request`, { phoneNumber: phoneNumber })
    .then(response => {
      if (response.data.error) {
        dispatch({ type: type.UPDATE_ERROR, payload: true });
        dispatch({
          type: type.UPDATE_MESSAGE,
          payload: getErrorMessage(response.data.error)
        });
      } else {
        // Success!
        dispatch({ type: type.VERIFICATION_SENT, payload: true });

        // Present the modal to be able to input token
        dispatch({ type: type.UPDATE_MODAL, payload: true });

        // Update as per usual
        dispatch({ type: type.UPDATE_ERROR, payload: false });
        dispatch({ type: type.UPDATE_MESSAGE, payload: null });
      }
    })
    .catch(function(error) {
      console.log(error);
    });
};
