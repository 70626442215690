import React from "react";
import Button from "@material-ui/core/Button";

function handleButtonPushed({buttonPushed}) {
  buttonPushed()
}

export default function PrimaryButton(props) {
  return (
    <div>
      <Button
        variant="contained"
        size="large"
        style={{ backgroundColor: "#F34213", color: "white" }}
        onClick={e => handleButtonPushed(props)}
        disabled={props.disabled}
      >
        {props.title}
      </Button>
    </div>
  );
}
