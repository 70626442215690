import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route
} from "react-router-dom";
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import {ThemeProvider} from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import "./App.css";

import logo from "./resources/images/logo_v6.png"; // Tell Webpack this JS file uses this image

/** Components */
import GroupReminderPage from "./pages/grouppage";
import AppPage from "./pages/apppage";
import HomePage from "./pages/homepage";
import NewGroupReminderPage from "./pages/newGroupReminderPage";
import AndroidBetaBanner from "./components/AndroidBetaBanner";


const theme = createMuiTheme({
  palette: {
    background: {
      default:"#f0eff4"
    },
    primary: {
      main: '#f34213',
      light: '#f34213',
      dark: '#aa2e0d',
      contrastText: 'white'
    },
    secondary: {
      main: '#006efd',
      light: '#5fa2ff',
      dark: '#0074d9',
      contrastText: 'white'
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});


class App extends Component {
  render() {
    return (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AndroidBetaBanner />
          <Router>
            <div className="App">
              <header>
                <br />
                <img
                    style={{ width: "100px", borderRadius: 10 }}
                    src={logo}
                    alt="Logo"
                />
              </header>
              <div>
                <Route
                    exact
                    path="/"
                    component={HomePage}
                />
                <Route
                    exact
                    path="/group/reminder/:id"
                    component={GroupReminderPage}
                />
                <Route
                    exact
                    path="/app/group/reminder/:id"
                    component={GroupReminderPage}
                />
                <Route
                    exact
                    path="/app/group/reminder/v4/:id"
                    component={NewGroupReminderPage}
                />
                <Route
                    exact
                    path="/v4/:id"
                    component={NewGroupReminderPage}
                />
              </div>
            </div>
          </Router>
        </ThemeProvider>
    );
  }
}

export default connect()(App);
