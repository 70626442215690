
//** GROUP ACTIONS */
export const ADD_USER_TO_GROUP = 'ADD_USER_TO_GROUP'

export const VERIFICATION_SENT = 'VERIFICATION_SENT'

export const UPDATE_GROUP_PHONE_NUMBER_TEXTFIELD = 'UPDATE_GROUP_PHONE_NUMBER_TEXTFIELD'

export const UPDATE_ERROR = 'UPDATE_ERROR'

export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'

export const UPDATE_MODAL = 'UPDATE_MODAL'

export const UPDATE_TOKEN = 'UPDATE_TOKEN'

export const UPDATE_VERIFIED = 'UPDATE_VERIFIED'

export const UPDATE_REMINDER_DATA = 'UPDATE_REMINDER_DATA'

export const UPDATE_DIALOG_OPEN = 'UPDATE_DIALOG_OPEN'

export const UPDATE_AGREE_TO_TERMS = 'UPDATE_AGREE_TO_TERMS'

export const UPDATE_FINISHED_RESPONSE = 'UPDATE_FINISHED_RESPONSE'
