import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../redux/actions/groupReminderActions";
import queryString from 'query-string'
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import MetaTags from 'react-meta-tags';
import { isIOS } from 'react-device-detect';

// Components
import footer from "../components/footer";
import Footer from "../components/footer";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from '@material-ui/core/Icon';
import EditIcon from "@material-ui/icons/Edit";
import Divider from "@material-ui/core/Divider";
import SvgIcon from '@material-ui/core/SvgIcon';
import MessageIcon from '@material-ui/icons/Message';
import StopIcon from '@material-ui/icons/Stop';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import FormDialog from "../components/dialog";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const appInfo = {
    imageUrl: "https://rmdr.me/logo_v6.png",
    name: "Text Me That for Android",
    publisher: "Text Me That",
    infoText: "Sign up for the beta today.",
    linkButtonText: "VIEW",
    linkUrl: "https://textmethat.io/android-beta"
};

class NewGroupReminderPage extends Component {
    constructor(props) {
        super(props);

        this.reminderId = this.props.location.pathname.substring(this.props.location.pathname.lastIndexOf('/') + 1)

        // const values = queryString.parse(this.props.location.search)
        // this.reminderId = values.id
        props.getReminderData(this.reminderId)

        let width = window.innerWidth
        this.isMobile = width <= 1000;

        this.icons = {
            reminderMessage: MessageIcon,
            reminderNotes: SpeakerNotesIcon,
            reminderTime: WatchLaterIcon,
            frequency: FiberManualRecordIcon,
            notification: StopIcon,
        }

        this.state = {
            accepted: false
        }
    }

    // This is make sure the last divier doesnt get added to the reminder page
    GetDivider(currentKey, lastKey) {
        if(currentKey !== lastKey){
            return <Divider />
        }
        return null
    }

    reminderDetails() {
        let details = []

        for (const [key, Value] of Object.entries(this.icons)) {
            let info = `${this.props.group[key]}`
            let lastKey = Object.keys(this.icons)[Object.keys(this.icons).length-1]

            let entry = (
                <div key={key}>
                    <ListItem key={key}>
                        <ListItemIcon>
                            <Value />
                        </ListItemIcon>

                        <Grid
                            container
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid item>
                                <ListItemText primary={info} />
                            </Grid>

                            {/*<Grid item>*/}
                            {/*    <ListItemText primary={info} />*/}
                            {/*</Grid>*/}
                        </Grid>

                    </ListItem>

                    { this.GetDivider(key, lastKey) }
                </div>

            )
            details.push(entry)
        }
        return details
    }

    handleAction(accepted) {
        this.setState({ accepted: accepted })
        this.props.updateDialog(true)
    }

    actionButtons() {
        let direction = this.isMobile ? 'row' : 'column'
        let style = this.isMobile ? {} : { width: '270px' }

        return (
            <Grid
                container
                justify="space-between"
                alignItems="center"
                direction={direction}
                spacing={1}
            >

                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <Button variant="contained" color="secondary" size={"large"} fullWidth={true} style={style}
                            onClick={() => this.handleAction(true)}>
                        Accept Reminder
                    </Button>
                    <br/>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <Button variant="contained" color="primary" size={"large"} fullWidth={true} style={style}
                            onClick={() => this.handleAction(false)}>
                        Deny Reminder
                    </Button>
                </Grid>
            </Grid>
        )
    }

    showOptions() {
        if (!this.isMobile) {
            if (this.props.group.finishedResponse) {
                return (
                    <Typography variant="h5" component="h3" color={"secondary"} >
                        Reminder has been {this.state.accepted ? 'accepted' : 'denied'}.
                    </Typography>
                )
            }

            return this.actionButtons()
        }

        if (this.isMobile) {
            if (this.props.group.finishedResponse) {
                return (
                        <Footer>
                            <Typography variant="h5" component="h3" color={"secondary"} >
                                Reminder has been {this.state.accepted ? 'accepted' : 'denied'}.
                            </Typography>
                        </Footer>
                    )
            }
        }
        return (
            <Grid item>
                <Footer>
                    {this.actionButtons()}
                </Footer>
            </Grid>
        )
    }

    showDialog() {
        return (
            <FormDialog
                // Open Dialog details
                openDialog={this.props.group.openDialog}
                openCloseDialogAction={this.props.updateDialog}

                // Terms
                agreeToTerm={this.props.group.agreeToReceivingReminder}
                agreeToTermsClicked={this.props.agreeToTerm}

                //Whether they clicked accept or deny
                clickedAccepted={this.state.accepted}

                // This action to handle valid phone number and accept/deny invite
                reminderId={this.props.group.reminderID}
                acceptInvite={this.props.acceptInvite}
            />
        )
    }

    renderBottomSection() {
        if (this.props.group.errorExists) {
            return <div>{this.props.group.message}</div>
        }

        if (this.props.group.reminderMessage === "") {
            return null
        }

        return (
            <>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                    <List style={{backgroundColor: 'white', borderRadius: 12}}>
                        { this.reminderDetails() }
                    </List>
                </Grid>
                {this.showOptions()}
            </>
        )
    }

    render() {
        return (
            <div style={{padding: 20}}>
                <MetaTags>
                    <title>Group Reminder Page</title>
                    <meta property="apple-itunes-app" content="app-clip-bundle-id=com.ollieandfinch.unforgettable.Clip, app-id=1329223000" />
                    <meta name="apple-itunes-app" content="app-clip-bundle-id=com.ollieandfinch.unforgettable.Clip, app-id=1329223000" />
                    <meta property="og:apple-itunes-app" content="app-clip-bundle-id=com.ollieandfinch.unforgettable.Clip, app-id=1329223000" />
                </MetaTags>
                {this.showDialog()}
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                    spacing={5}
                >
                    <Grid item>
                        <Typography variant="h4" component="h3" color={"primary"} sx={{ fontWeight: 'bold' }}>
                            Reminder Invite
                        </Typography>
                    </Grid>

                    {this.renderBottomSection()}
                </Grid>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return state;
}

export default connect(
    mapStateToProps,
    actions
)(NewGroupReminderPage);
