import React from "react";

//** TextField UI*/
import TextField from "@material-ui/core/TextField";

function handleTextFieldChange({ handleTextField }, value) {
  handleTextField(value);
}

export default function PrimaryTextField(props) {

  let { textValue } = props;

  return (
    <div>
      <TextField
        id="outlined-name"
        label={props.placeholder}
        value={textValue}
        onChange={e => handleTextFieldChange(props, e.target.value)}
        margin="normal"
        variant="outlined"
        type={props.type}
        style={{ border: "#F34213" }}
        helperText={props.helperText}
      />
    </div>
  );
}
