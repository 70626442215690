import React from "react"

//** Card UI*/
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";


export default function ReminderCard(props) {
  const bull = <span>•</span>;

  return (
    <Card minwidth={400} raised={true}>
      <CardContent>
        <Typography
          gutterBottom
          color="textSecondary"
          variant="h5"
          component="h2"
          align="justify"
        >
          Time
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          component="p"
          align="justify"
        >
          {props.time}
        </Typography>
      </CardContent>
      <CardContent>
        <Typography
          gutterBottom
          color="textSecondary"
          variant="h5"
          component="h2"
          align="justify"
        >
          Message
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          component="p"
          align="justify"
        >
          {props.reminderMessage}
        </Typography>
      </CardContent>
      <CardContent>
        <Typography
          gutterBottom
          color="textSecondary"
          variant="h5"
          component="h2"
          align="justify"
        >
          Notes
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          component="p"
          align="justify"
        >
          {props.reminderNotes}
        </Typography>
      </CardContent>
      <CardContent>
        <Typography color="textSecondary" gutterBottom align="justify">
          By: -
        </Typography>
      </CardContent>
    </Card>
  )
}
